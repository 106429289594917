import React from 'react'
import appStore from '../../images/app-store.png'
import googlePlay from '../../images/google-play.png'
import './index.scss'

export const Banner = () => {
  return (
    <section id="banner">
    <div className="banner-container">
      <div className="banner-text">Mais precisão para o policial e segurança para o cidadão.</div>
      <div className="banner-stores">
        <a href="https://apps.apple.com/br/app/agente-de-campo/id1513040092" className="qrcode qrcode-ios" target="_blank" rel="noopener noreferrer">
          <img src={ appStore } alt="App Store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=br.gov.sinesp.agentedecampo" className="qrcode qrcode-android" target="_blank" rel="noopener noreferrer">
          <img src={ googlePlay } alt="Google Play" />
        </a>
      </div>
    </div>
  </section>
  )
}
