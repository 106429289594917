import React from 'react'
import { Header } from './components/header'
import { Footer } from './components/footer'
import { About } from './components/about'
import { Functionalities } from './components/funcionalities'
import { Banner } from './components/banner'
// import { Ratings } from './components/ratings'

export const App = () => {
  return (
    <>
      <Header />
      <About />
      <Functionalities />
      <Banner />
      {/* <Ratings /> */}
      <Footer />
    </>
  )
}
