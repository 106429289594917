import React from 'react'
import mjGov from '../../images/mj-gov.png'
import './index.scss'

const year = new Date().getFullYear()

export const Footer = () => {
  return (
    <footer class="footer">
      <div class="footer-container">
        <div class="footer-credits">
          <div class="senasp-and-mj">
            <div>SENASP</div>
            <div>MINISTÉRIO DA JUSTIÇA E SEGURANÇA PÚBLICA</div>
          </div>          
        </div>
      </div>
      <div class="footer-copyright">
        <div class="container">
          <div class="insight-lab">{ year }, Ministério da Justiça e Segurança Pública.</div>
          <div class="policies">
            <a href="http://agente.stage.mj.gov.br/politica-privacidade" target="_blank" class="privacy-policy" rel="noopener noreferrer">Politica de privacidade</a>
            {/* <a href="#" class="cookies-policy">Politica de Cookies</a> */}
          </div>
        </div>
      </div>
    </footer>
  )
}
