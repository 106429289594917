import React from 'react'
import appStore from '../../images/app-store.png'
import googlePlay from '../../images/google-play.png'
import imgScreens from '../../images/screens.png'
import mjGov from '../../images/ministerio-da-justica.png'
import './index.scss'

export const About = () => {
  return (
    <section id="section-about">
      <div class="main-info">
        <div class="title">Agente de Campo</div>
        <div class="description">O Sistema de Inteligência destinado a Segurança Pública do Brasil. Criado por especialistas para trazer ainda mais eficiência no dia a dia dos policiais.</div>
        <div class="stores">
          <a href="https://apps.apple.com/br/app/agente-de-campo/id1513040092" target="_blank" rel="noopener noreferrer">
            <img src={ appStore } alt="App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=br.gov.sinesp.agentedecampo" target="_blank" rel="noopener noreferrer">
            <img src={ googlePlay } alt="Google Play" />
          </a>
        </div>
        <div class="logo-mj">
          <img src={ mjGov } alt="Ministério da Justiça e Segurança Pública" />
        </div>
      </div>
      <div class="screens-imgs">
        <img src={ imgScreens } alt="App Agente de Campo" />
      </div>
    </section>
  )
}
