import React from 'react'
import database from '../../images/extensive-database.png'
import modules from '../../images/quick-access-modules.png'
import map from '../../images/ppdated-map.png'
import './index.scss'

export const Functionalities = () => {
  return (
    <section id="section-functionalities">
      <div class="functionality">
        <div class="func-image">
          <img src={ database } alt="Fontes de dados" />
        </div>
        <div class="func-title">Fontes de dados</div>
        <div class="func-description">Confiáveis e seguras para buscas mais completas, integradas e precisas</div>
      </div>
      <div class="functionality">
        <div class="func-image">
          <img src={ modules } alt="Acesso rápido" />
        </div>
        <div class="func-title">Acesso rápido</div>
        <div class="func-description">Transformando as abordagens tradicionais em processos mais ágeis e eficientes</div>
      </div>
      <div class="functionality">
        <div id="func-map" class="func-image">
          <img src={ map } alt="Rastreamento" />
        </div>
        <div class="func-title">Rastreamento</div>
        <div class="func-description">Visualize as últimas passagens por radares de veículos que possuem restrições de roubo ou furto</div>
      </div>
    </section>
  )
}
