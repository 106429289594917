import React from 'react'
import logo from '../../images/sinesp.png'
import './index.scss'

const LINK_MANUAL = `files/Sinesp Agente de Campo -v3- Instalação.pdf`

export const Header = () => {
  return (
    <header className="header">
      <img class="logo-sinesp" src={ logo } alt="Sinesp" />
      <nav class="menu">
        <a href={ LINK_MANUAL } class="menu-item" target="_blank" rel="noopener noreferrer">Manual de Instalação</a>
        <a href="#section-functionalities" class="menu-item" rel="noopener noreferrer">Funcionalidades</a>
        <a href="#section-about" class="menu-item" rel="noopener noreferrer">Sobre</a>
        {/* <a href="#section-ratings" class="menu-item">Avaliações</a> */}
      </nav>
    </header>
  )
}
